:root {
    --onboarding-content-width: 800px;
    --onboarding-content-width-sm: 400px;
}

.web-onboarding {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.web-onboarding-v {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.content-onboarding {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.onboarding-buttons-instance {
    margin-top: 50px;
}

.onboard-title {
    color: #000000;
    font-family: var(--heading-desktop-h5-font-family);
    font-size: var(--heading-desktop-h5-font-size);
    font-style: var(--heading-desktop-h5-font-style);
    font-weight: var(--heading-desktop-h5-font-weight);
    letter-spacing: var(--heading-desktop-h5-letter-spacing);
    line-height: var(--heading-desktop-h5-line-height);
    text-align: center;
    white-space: nowrap;
}

.web-onboarding .p {
    font-family: var(--text-medium-normal-font-family);
    font-size: var(--text-medium-normal-font-size);
    font-style: var(--text-medium-normal-font-style);
    font-weight: var(--text-medium-normal-font-weight);
    letter-spacing: var(--text-medium-normal-letter-spacing);
    line-height: var(--text-medium-normal-line-height);
    text-align: center;
    margin-top: 20px;
    max-width: var(--onboarding-content-width);
}

/* Components: Navbar, Impressum */
.navbar-onboarding {
    align-items: center;
    background-color: var(--semantic-background-color-primary);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: var(--semantic-border-primary);
    display: flex;
    flex-direction: column;
    height: 72px;
    justify-content: center;
    padding: 0px 64px;
    position: relative;
    width: 100%;
}

.navbar-onboarding .container {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 6px;
    justify-content: center;
    position: relative;
}

.navbar-onboarding .logo-p-only {
    height: 40.91px;
    object-fit: cover;
    position: relative;
    width: 42px;
}

.navbar-onboarding .text-wrapper {
    color: var(--primary);
    font-family: "Roboto", Helvetica;
    font-size: 20px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 30px;
    position: relative;
}

.onboard-footer {
    color: #a9a9a9;
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    padding: 0px 10%;
    position: absolute;
    bottom: 20px;
}

.onboard-textarea {
    margin-top: 20px;
    background-color: #e7e7e7;
    width: 400px;
    height: 100px;
    padding: 10px;
}

.selection-box {
    margin: 20px 20px;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.selection-primary-active {
    color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    gap: 8px;

    width: 195.92px;

    background: #FFFFFF;
    /* Primary */
    border: 1px solid #CF5AB7;
    border-radius: 0px;

}

.selection-primary {
    color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    gap: 8px;

    width: 195.92px;

    background: #FFFFFF;
    /* Primary */
    border: 1px solid var(--grey);
    border-radius: 0px;

}

@media screen and (min-width:1440px) {
    .web-onboarding .progress {
        width: var(--onboarding-content-width);
    }

    .web-onboarding .line {
        width: var(--onboarding-content-width);
    }

    .web-onboarding .status-onboarding-bar {
        width: var(--onboarding-content-width);
    }

    .web-onboarding .p {
        max-width: var(--onboarding-content-width);
    }
}

@media screen and (max-width:1440px) {
    .web-onboarding .p {
        max-width: var(--onboarding-content-width-sm);
    }
}