.navbar-loggedin {
    align-items: center;
    background-color: var(--semantic-background-color-primary);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: var(--semantic-border-primary);
    display: flex;
    flex-direction: column;
    height: 72px;
    justify-content: center;
    padding: 0px 64px;
    position: relative;
}

.navbar-loggedin .logo-p-only {
    height: 40.91px;
    margin-left: -1.00px;
    object-fit: cover;
    position: relative;
    width: 42px;
}

.navbar-loggedin .text-wrapper {
    color: var(--primary);
    font-family: "Roboto", Helvetica;
    font-size: 20px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 30px;
    position: relative;
    width: 190px;
}

.navbar-loggedin .column {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    position: relative;
}

.navbar-loggedin .div {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 32px;
    position: relative;
}

.navbar-loggedin .link {
    flex: 0 0 auto;
    position: relative;
}

.navbar-loggedin .nav-link-dropdown-wrapper {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
}

.navbar-loggedin .nav-link-dropdown {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 4px;
    justify-content: center;
    position: relative;
    width: 100%;
}

.navbar-loggedin .chevron-down {
    height: 24px;
    position: relative;
    width: 24px;
}

.navbar-loggedin .column-2 {
    position: relative;
}

.navbar-loggedin .icon-user {
    height: 40px !important;
    margin-right: -1.00px !important;
    position: relative !important;
    width: 40px !important;
}