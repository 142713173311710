.style-primary-small .button {
    color: var(--semantic-text-alternate);
    font-family: var(--text-regular-normal-font-family);
    font-size: var(--text-regular-normal-font-size);
    font-style: var(--text-regular-normal-font-style);
    font-weight: var(--text-regular-normal-font-weight);
    letter-spacing: var(--text-regular-normal-letter-spacing);
    line-height: var(--text-regular-normal-line-height);
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.style-primary-small {
    align-items: center;
    background-color: var(--primary);
    border: 1px solid;
    border-color: var(--primary);
    display: inline-flex;
    gap: 8px;
    justify-content: center;
    padding: 8px 20px;
    position: relative;
}

.style-primary .text-wrapper {
    color: var(--semantic-text-alternate);
    font-family: var(--text-regular-normal-font-family);
    font-size: var(--text-regular-normal-font-size);
    font-style: var(--text-regular-normal-font-style);
    font-weight: var(--text-regular-normal-font-weight);
    letter-spacing: var(--text-regular-normal-letter-spacing);
    line-height: var(--text-regular-normal-line-height);
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.type-default {
    align-items: center;
    border: 1px solid;
    border-color: var(--primitive-color-neutral-black);
    display: flex;
    gap: 8px;
    padding: 12px;
    position: relative;
    width: 562px;
}

.type-default .placeholder {
    color: var(--primitive-color-neutral-neutral);
    flex: 1;
    font-family: var(--text-regular-normal-font-family);
    font-size: var(--text-regular-normal-font-size);
    font-style: var(--text-regular-normal-font-style);
    font-weight: var(--text-regular-normal-font-weight);
    letter-spacing: var(--text-regular-normal-letter-spacing);
    line-height: var(--text-regular-normal-line-height);
    position: relative;
}

.style-secondary {
    align-items: center;
    border: 1px solid;
    border-color: var(--primitive-color-neutral-black);
    display: inline-flex;
    gap: 8px;
    justify-content: center;
    padding: 12px 24px;
    position: relative;
}

.style-secondary .div {
    color: var(--semantic-text-primary);
    font-family: var(--text-regular-normal-font-family);
    font-size: var(--text-regular-normal-font-size);
    font-style: var(--text-regular-normal-font-style);
    font-weight: var(--text-regular-normal-font-weight);
    letter-spacing: var(--text-regular-normal-letter-spacing);
    line-height: var(--text-regular-normal-line-height);
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

@media screen and (min-width:1440px) {

    .style-primary {
        align-items: center;
        background-color: var(--primary);
        border: 1px solid;
        border-color: var(--primary);
        display: inline-flex;
        gap: 8px;
        justify-content: center;
        padding: 12px 24px;
        position: relative;
    }

    .container {
        width: 1360px;
    }
}

@media screen and (max-width:1440px) {

    .style-primary {
        align-items: center;
        background-color: var(--primary);
        border: 1px solid;
        border-color: var(--primary);
        display: inline-flex;
        gap: 8px;
        justify-content: center;
        padding: 6px 16px;
        position: relative;
    }

    .container {
        width: 350px;
    }
}