/* login */
.login-container {
  margin: 0px auto;
  max-width: 600px;
}


/* DSGVO */
.DSGVO-Container p {
  margin: 20px;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  font-family: "Roboto-Black";
}

.DSGVO-Container h1,
h2 {
  text-transform: capitalize;
  letter-spacing: 0;
  font-style: normal;
  font-weight: bolder;
  position: relative;
  padding: 0 0 10px;
  line-height: normal;
  color: var(--primary);
  margin: 0;
}

.DSGVO-Container h2 {
  font-size: 26px;
}

.DSGVO-Container h1 {
  font-size: 36px;
  margin-top: 50px;
}

.Container {
  width: var(--medium-screen-width);
  margin-left: auto;
  margin-right: auto;
  padding-top: 40px;
  padding-bottom: 40px;
}

/* REFACTOR */
.buttons {
  cursor: pointer;
}

.web-landingpage .content-point {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 100%;
}

/* generated */
.divider {
  align-self: stretch;
  background-color: var(--semantic-border-primary);
  border: 1px solid;
  border-color: var(--semantic-border-primary);
  height: 1px;
  position: relative;
  width: 100%;
}

.footer-last-line {
  width: 100%;
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.footer-last-line .footer-links {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
}

.footer-last-line .text-wrapper-2 {
  color: var(--semantic-link-primary);
  font-family: var(--text-small-link-font-family);
  font-size: var(--text-small-link-font-size);
  font-style: var(--text-small-link-font-style);
  font-weight: var(--text-small-link-font-weight);
  letter-spacing: var(--text-small-link-letter-spacing);
  line-height: var(--text-small-link-line-height);
  margin-top: -1.00px;
  position: relative;
  text-decoration: underline;
  white-space: nowrap;
  width: fit-content;
}

.footer-text {
  align-self: stretch;
  color: var(--semantic-text-primary);
  font-family: var(--text-regular-normal-font-family);
  font-size: var(--text-regular-normal-font-size);
  font-style: var(--text-regular-normal-font-style);
  font-weight: var(--text-regular-normal-font-weight);
  letter-spacing: var(--text-regular-normal-letter-spacing);
  line-height: var(--text-regular-normal-line-height);
  position: relative;
}

.input-form {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
  width: 100%;
}

.web-landingpage .rights-impressum-instance {
  flex: 0 0 auto !important;
}

.web-landingpage {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.logo-div {
  display: flex;
  flex-direction: row;
}

.web-landingpage .web-landingpage-v {
  width: 100%;
}

.web-landingpage .div-content {
  align-items: center;
  background-color: var(--semantic-background-color-primary);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

/* Anima Section  */
@media screen and (min-width:1440px) {

  .web-landingpage .web-home {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .web-landingpage .navbar {
    align-items: center;
    background-color: var(--semantic-background-color-primary);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: var(--semantic-border-primary);
    display: flex;
    flex-direction: column;
    height: 72px;
    justify-content: center;
    padding: 0px 64px;
    position: relative;
    z-index: 10;
  }

  .container {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 6px;
    justify-content: space-between;
    position: relative;
  }

  .web-landingpage .logo-p-only {
    height: 40.91px;
    object-fit: cover;
    position: relative;
    width: 42px;
  }

  .text-primary {
    color: var(--primary);
    font-family: "Roboto", Helvetica;
    font-size: 20px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 30px;
    position: relative;
    width: 240px;
    align-self: center;
  }

  .web-landingpage .column {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 529px;
  }

  .web-landingpage .link {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    width: 102px;
  }

  .web-landingpage .nav-link-dropdown {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 4px;
    justify-content: center;
    position: relative;
    width: 100%;
  }

  .web-landingpage .icon-instance-node-2 {
    height: 24px;
    position: relative;
    width: 24px;
  }

  .web-landingpage .style-primary-small-true-alternate-false-icon-position-no-icon-wrapper {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 529px;
  }

  .web-landingpage .style-primary-small-true-alternate-false-icon-position-no-icon {
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
    flex: 0 0 auto !important;
    margin-bottom: -2.00px !important;
    margin-right: -2.00px !important;
    margin-top: -2.00px !important;
  }

  .web-landingpage .style-primary-small-instance {
    font-family: "Roboto", Helvetica !important;
    font-size: 16px !important;
    font-style: unset !important;
    font-weight: 400 !important;
    letter-spacing: 0 !important;
    line-height: 24px !important;
    margin-left: -1.00px !important;
    margin-top: -1.00px !important;
  }

  .web-landingpage .header {
    align-items: center;
    background-color: var(--semantic-background-color-primary);
    display: flex;
    flex-direction: column;
    gap: 80px;
    height: 900px;
    padding: 0px 64px;
    position: relative;
    width: 1440px;
  }

  .web-landingpage .div-2 {
    align-items: center;
    align-self: stretch;
    justify-content: space-between;
    display: flex;
    flex: 1;
    flex-grow: 1;
    position: relative;
    width: 100%;
  }

  .web-landingpage .column-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 32px;
    justify-content: center;
    padding: 0px 80px 0px 0px;
    position: relative;
  }



  .web-landingpage .content-5 {
    text-align: left;
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 24px;
    position: relative;
  }

  .web-landingpage .medium-length-hero {
    align-self: stretch;
    color: transparent;
    font-family: var(--heading-desktop-h1-font-family);
    font-size: var(--heading-desktop-h1-font-size);
    font-style: var(--heading-desktop-h1-font-style);
    font-weight: var(--heading-desktop-h1-font-weight);
    letter-spacing: var(--heading-desktop-h1-letter-spacing);
    line-height: var(--heading-desktop-h1-line-height);
    margin-top: -1.00px;
    position: relative;
  }

  .web-landingpage .span {
    color: #000000;
    font-family: var(--heading-desktop-h1-font-family);
    font-size: var(--heading-desktop-h1-font-size);
    font-style: var(--heading-desktop-h1-font-style);
    font-weight: var(--heading-desktop-h1-font-weight);
    letter-spacing: var(--heading-desktop-h1-letter-spacing);
    line-height: var(--heading-desktop-h1-line-height);
  }

  .web-landingpage .text-wrapper-4 {
    color: #7be0ad;
    font-family: var(--heading-desktop-h1-font-family);
    font-size: var(--heading-desktop-h1-font-size);
    font-style: var(--heading-desktop-h1-font-style);
    font-weight: var(--heading-desktop-h1-font-weight);
    letter-spacing: var(--heading-desktop-h1-letter-spacing);
    line-height: var(--heading-desktop-h1-line-height);
  }

  .web-landingpage .p {
    align-self: stretch;
    color: var(--semantic-text-primary);
    font-family: var(--text-medium-normal-font-family);
    font-size: var(--text-medium-normal-font-size);
    font-style: var(--text-medium-normal-font-style);
    font-weight: var(--text-medium-normal-font-weight);
    letter-spacing: var(--text-medium-normal-letter-spacing);
    line-height: var(--text-medium-normal-line-height);
    position: relative;
  }

  .web-landingpage .buttons-instance {
    position: relative !important;
  }

  .web-landingpage .buttons-2 {
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
    display: flex !important;
    height: 44px !important;
    left: -2px !important;
    top: -2px !important;
    width: 200px !important;
  }

  .web-landingpage .content-2 {
    flex: 1;
    flex-grow: 1;
    height: 900px;
    position: relative;
  }

  .web-landingpage .content-pic {
    flex: 1;
    flex-grow: 1;
    height: 900px;
    position: relative;
  }

  .web-landingpage .pic-col-right {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 2732px;
    left: 0;
    position: absolute;
    width: 319px;
    z-index: 5;
  }

  .web-landingpage .pic-col-left {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 2732px;
    left: 337px;
    position: absolute;
    top: 0;
    width: 319px;
  }

  .web-landingpage .placeholder-image {
    align-self: stretch;
    height: 340px;
    object-fit: cover;
    position: relative;
    width: 100%;
  }

  .web-landingpage .img {
    align-self: stretch;
    height: 188px;
    object-fit: cover;
    position: relative;
    width: 100%;
  }

  .web-landingpage .content {
    text-align: left;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 24px;
    position: relative;
    width: 100%;
  }

  .web-landingpage .content-box {
    text-align: left;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 24px;
    position: relative;
    width: 100%;
  }

  .web-landingpage .div-content {
    gap: 80px;
    padding: 112px 64px;
    width: 1440px;
  }



  .web-landingpage .heading {
    color: transparent;
    font-family: var(--heading-desktop-h3-font-family);
    font-size: var(--heading-desktop-h3-font-size);
    font-style: var(--heading-desktop-h3-font-style);
    font-weight: var(--heading-desktop-h3-font-weight);
    letter-spacing: var(--heading-desktop-h3-letter-spacing);
    line-height: var(--heading-desktop-h3-line-height);
    margin-top: -1.00px;
    position: relative;
    text-align: center;
    width: 768px;
  }

  .web-landingpage .text-wrapper-5 {
    color: #000000;
    font-family: var(--heading-desktop-h3-font-family);
    font-size: var(--heading-desktop-h3-font-size);
    font-style: var(--heading-desktop-h3-font-style);
    font-weight: var(--heading-desktop-h3-font-weight);
    letter-spacing: var(--heading-desktop-h3-letter-spacing);
    line-height: var(--heading-desktop-h3-line-height);
  }

  .web-landingpage .text-wrapper-6 {
    color: #7be0ad;
    font-family: var(--heading-desktop-h3-font-family);
    font-size: var(--heading-desktop-h3-font-size);
    font-style: var(--heading-desktop-h3-font-style);
    font-weight: var(--heading-desktop-h3-font-weight);
    letter-spacing: var(--heading-desktop-h3-letter-spacing);
    line-height: var(--heading-desktop-h3-line-height);
  }

  .web-landingpage .row-wrapper {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 64px;
    position: relative;
    width: 100%;
  }

  .web-landingpage .row {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 32px;
    justify-content: center;
    position: relative;
    width: 100%;
  }

  .web-landingpage .arg-point {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 32px;
    position: relative;
  }

  .web-landingpage .section-title {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 24px;
    position: relative;
    width: 100%;
  }

  .web-landingpage .icon-relume {
    height: 48px !important;
    position: relative !important;
    width: 48px !important;
  }

  .web-landingpage .content-point {
    gap: 16px;
  }

  .web-landingpage .heading-2 {
    align-self: stretch;
    color: var(--black);
    font-family: var(--heading-desktop-h5-font-family);
    font-size: var(--heading-desktop-h5-font-size);
    font-style: var(--heading-desktop-h5-font-style);
    font-weight: var(--heading-desktop-h5-font-weight);
    letter-spacing: var(--heading-desktop-h5-letter-spacing);
    line-height: var(--heading-desktop-h5-line-height);
    margin-top: -1.00px;
    position: relative;
    text-align: center;
  }

  .web-landingpage .text {
    align-self: stretch;
    color: var(--semantic-text-primary);
    font-family: var(--text-regular-normal-font-family);
    font-size: var(--text-regular-normal-font-size);
    font-style: var(--text-regular-normal-font-style);
    font-weight: var(--text-regular-normal-font-weight);
    letter-spacing: var(--text-regular-normal-letter-spacing);
    line-height: var(--text-regular-normal-line-height);
    position: relative;
    text-align: center;
  }

  .web-landingpage .action {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    position: relative;
    width: 100%;
  }

  .web-landingpage .style-link-small {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    position: relative;
  }

  .web-landingpage .div-3 {
    align-items: center;
    background-color: var(--semantic-background-color-primary);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 80px;
    padding: 112px 64px;
    position: relative;
    width: 1440px;
  }

  .web-landingpage .container-wrapper {
    align-items: flex-start;
    background-color: var(--semantic-background-color-primary);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 80px;
    padding: 112px 64px;
    position: relative;
    width: 1440px;
  }

  .web-landingpage .container-wrapper-box {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 80px;
    position: relative;
    width: 100%;
  }

  .web-landingpage .div-box {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 32px;
    position: relative;
  }

  .web-landingpage .div-5 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 32px;
    position: relative;
    width: 100%;
  }

  .footer-div-5 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 32px;
    position: relative;
    width: 100%;
  }

  .web-landingpage .div-6 {
    text-align: left;
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 16px;
    position: relative;
    width: 100%;
  }

  .web-landingpage .tagline-wrapper {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    position: relative;
  }

  .web-landingpage .tagline {
    color: var(--semantic-text-primary);
    font-family: var(--heading-desktop-tagline-font-family);
    font-size: var(--heading-desktop-tagline-font-size);
    font-style: var(--heading-desktop-tagline-font-style);
    font-weight: var(--heading-desktop-tagline-font-weight);
    letter-spacing: var(--heading-desktop-tagline-letter-spacing);
    line-height: var(--heading-desktop-tagline-line-height);
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .web-landingpage .heading-3 {
    align-self: stretch;
    color: transparent;
    font-family: var(--heading-desktop-h2-font-family);
    font-size: var(--heading-desktop-h2-font-size);
    font-style: var(--heading-desktop-h2-font-style);
    font-weight: var(--heading-desktop-h2-font-weight);
    letter-spacing: var(--heading-desktop-h2-letter-spacing);
    line-height: var(--heading-desktop-h2-line-height);
    margin-top: -1.00px;
    position: relative;
  }

  .web-landingpage .text-wrapper-7 {
    color: #7be0ad;
    font-family: var(--heading-desktop-h2-font-family);
    font-size: var(--heading-desktop-h2-font-size);
    font-style: var(--heading-desktop-h2-font-style);
    font-weight: var(--heading-desktop-h2-font-weight);
    letter-spacing: var(--heading-desktop-h2-letter-spacing);
    line-height: var(--heading-desktop-h2-line-height);
  }

  .web-landingpage .text-wrapper-8 {
    color: #000000;
    font-family: var(--heading-desktop-h2-font-family);
    font-size: var(--heading-desktop-h2-font-size);
    font-style: var(--heading-desktop-h2-font-style);
    font-weight: var(--heading-desktop-h2-font-weight);
    letter-spacing: var(--heading-desktop-h2-letter-spacing);
    line-height: var(--heading-desktop-h2-line-height);
  }

  .web-landingpage .buttons-3 {
    font-family: "Roboto", Helvetica !important;
    font-size: 16px !important;
    font-style: unset !important;
    font-weight: 400 !important;
    letter-spacing: 0 !important;
    line-height: 24px !important;
    margin-left: -11.54px !important;
    margin-right: -5.54px !important;
    margin-top: -5.00px !important;
  }

  .web-landingpage .placeholder-image-half {
    flex: 1;
    flex-grow: 1;
    height: 640px;
    object-fit: cover;
    position: relative;
  }

  .web-landingpage .content-5 {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 24px;
    position: relative;
  }

  .web-landingpage .heading-4 {
    align-self: stretch;
    color: transparent;
    font-family: var(--heading-desktop-h3-font-family);
    font-size: var(--heading-desktop-h3-font-size);
    font-style: var(--heading-desktop-h3-font-style);
    font-weight: var(--heading-desktop-h3-font-weight);
    letter-spacing: var(--heading-desktop-h3-letter-spacing);
    line-height: var(--heading-desktop-h3-line-height);
    margin-top: -1.00px;
    position: relative;
  }

  .web-landingpage .layout {
    align-items: flex-start;
    background-color: var(--secondary);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 80px;
    padding: 112px 64px;
    position: relative;
    width: 1440px;
  }

  .web-landingpage .text-wrapper-9 {
    align-self: stretch;
    color: var(--semantic-text-primary);
    font-family: var(--heading-desktop-h3-font-family);
    font-size: var(--heading-desktop-h3-font-size);
    font-style: var(--heading-desktop-h3-font-style);
    font-weight: var(--heading-desktop-h3-font-weight);
    letter-spacing: var(--heading-desktop-h3-letter-spacing);
    line-height: var(--heading-desktop-h3-line-height);
    margin-top: -1.00px;
    position: relative;
  }

  .web-landingpage .list {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 24px;
    padding: 8px 0px;
    position: relative;
    width: 100%;
  }

  .web-landingpage .list-item {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 8px;
    position: relative;
  }

  .web-landingpage .number {
    align-self: stretch;
    color: var(--semantic-text-primary);
    font-family: var(--heading-desktop-h2-font-family);
    font-size: var(--heading-desktop-h2-font-size);
    font-style: var(--heading-desktop-h2-font-style);
    font-weight: var(--heading-desktop-h2-font-weight);
    letter-spacing: var(--heading-desktop-h2-letter-spacing);
    line-height: var(--heading-desktop-h2-line-height);
    margin-top: -1.00px;
    position: relative;
  }

  .text-wrapper-9 {
    align-self: stretch;
    color: var(--semantic-text-primary);
    font-family: var(--text-regular-normal-font-family);
    font-size: var(--text-regular-normal-font-size);
    font-style: var(--text-regular-normal-font-style);
    font-weight: var(--text-regular-normal-font-weight);
    letter-spacing: var(--text-regular-normal-letter-spacing);
    line-height: var(--text-regular-normal-line-height);
    position: relative;
  }

  .web-landingpage .content-6 {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 32px;
    position: relative;
    width: 768px;
  }

  .web-landingpage .logo-color-dark {
    height: 48px !important;
    position: relative !important;
    width: 120px !important;
  }

  .web-landingpage .quote {
    align-self: stretch;
    color: var(--semantic-text-primary);
    font-family: var(--heading-desktop-h5-font-family);
    font-size: var(--heading-desktop-h5-font-size);
    font-style: var(--heading-desktop-h5-font-style);
    font-weight: var(--heading-desktop-h5-font-weight);
    letter-spacing: var(--heading-desktop-h5-letter-spacing);
    line-height: var(--heading-desktop-h5-line-height);
    position: relative;
    text-align: center;
  }

  .web-landingpage .avatar {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 16px;
    position: relative;
    width: 300px;
  }

  .web-landingpage .avatar-image {
    height: 64px;
    object-fit: cover;
    position: relative;
    width: 64px;
  }

  .web-landingpage .div-7 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
    width: 100%;
  }

  .web-landingpage .text-2 {
    align-self: stretch;
    color: var(--semantic-text-primary);
    font-family: var(--text-regular-semi-bold-font-family);
    font-size: var(--text-regular-semi-bold-font-size);
    font-style: var(--text-regular-semi-bold-font-style);
    font-weight: var(--text-regular-semi-bold-font-weight);
    letter-spacing: var(--text-regular-semi-bold-letter-spacing);
    line-height: var(--text-regular-semi-bold-line-height);
    margin-top: -1.00px;
    position: relative;
    text-align: center;
  }

  .web-landingpage .buttons-4 {
    font-family: "Roboto", Helvetica !important;
    font-size: 16px !important;
    font-style: unset !important;
    font-weight: 400 !important;
    letter-spacing: 0 !important;
    line-height: 24px !important;
    margin-left: -6.54px !important;
    margin-right: -0.54px !important;
    margin-top: -5.00px !important;
  }

  .web-landingpage .placeholder-image-box {
    height: 600px;
    object-fit: cover;
    position: relative;
    width: 640px;
  }

  .footer {
    align-items: flex-start;
    background-color: var(--secondary);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 80px;
    padding: 80px 64px;
    position: relative;
  }

  .footer-content {
    align-items: flex-start;
    align-self: center;
    display: flex;
    gap: 332px;
    height: 248px;
    position: relative;
    width: 1440px;
  }

  .footer-newsletter {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;
    width: 500px;
  }

  .web-landingpage .form {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 16px;
    position: relative;
    width: 100%;
  }



  .style-secondary-small-false-alternate-false-icon-position-no-icon {
    display: flex !important;
    margin-bottom: -1.00px !important;
    margin-right: -1.00px !important;
    margin-top: -1.00px !important;
    width: 121px !important;
  }

  .web-landingpage .by-subscribing-you {
    align-self: stretch;
    color: var(--semantic-text-primary);
    font-family: var(--text-tiny-normal-font-family);
    font-size: var(--text-tiny-normal-font-size);
    font-style: var(--text-tiny-normal-font-style);
    font-weight: var(--text-tiny-normal-font-weight);
    letter-spacing: var(--text-tiny-normal-letter-spacing);
    line-height: var(--text-tiny-normal-line-height);
    position: relative;
  }

  .footer-links {
    align-items: flex-start;
    display: flex;
    gap: 70px;
    margin-right: -130.00px;
    position: relative;
    width: 610px;
  }

  .footer-links-column {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 16px;
    position: relative;
  }

  .footer-links-impressum {
    align-items: flex-start;
    display: flex;
    gap: 70px;
    position: relative;
  }

  .footer-links-title {
    align-self: stretch;
    color: var(--semantic-text-primary);
    font-family: var(--text-regular-semi-bold-font-family);
    font-size: var(--text-regular-semi-bold-font-size);
    font-style: var(--text-regular-semi-bold-font-style);
    font-weight: var(--text-regular-semi-bold-font-weight);
    letter-spacing: var(--text-regular-semi-bold-letter-spacing);
    line-height: var(--text-regular-semi-bold-line-height);
    margin-top: -1.00px;
    position: relative;
  }

  .web-landingpage .div-wrapper {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    padding: 8px 0px;
    position: relative;
    width: 100%;
  }

  .web-landingpage .text-wrapper-11 {
    color: var(--semantic-link-primary);
    flex: 1;
    font-family: var(--text-small-normal-font-family);
    font-size: var(--text-small-normal-font-size);
    font-style: var(--text-small-normal-font-style);
    font-weight: var(--text-small-normal-font-weight);
    letter-spacing: var(--text-small-normal-letter-spacing);
    line-height: var(--text-small-normal-line-height);
    margin-top: -1.00px;
    position: relative;
  }

  .footer-link-instance {
    align-items: center;
    align-self: center;
    display: flex;
    flex: 0 0 auto;
    gap: 12px;
    padding: 8px 0px;
    position: relative;
    width: 110px;
  }

  .web-landingpage .text-wrapper-12 {
    color: var(--semantic-link-primary);
    font-family: var(--text-small-normal-font-family);
    font-size: var(--text-small-normal-font-size);
    font-style: var(--text-small-normal-font-style);
    font-weight: var(--text-small-normal-font-weight);
    letter-spacing: var(--text-small-normal-letter-spacing);
    line-height: var(--text-small-normal-line-height);
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
}

/* Smaller Screens */
@media screen and (max-width:1440px) {
  .web-landingpage .web-landingpage-v {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .web-landingpage {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }

  .web-landingpage .navbar {
    align-items: center;
    background-color: var(--semantic-background-color-primary);
    display: flex;
    flex-direction: column;
    height: 64px;
    position: relative;
    width: 375px;
  }

  .web-landingpage .container {
    align-items: center;
    align-self: stretch;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: var(--semantic-border-primary);
    display: flex;
    gap: 16px;
    height: 64px;
    justify-content: center;
    padding: 0px 20px;
    position: relative;
    width: 100%;
  }

  .web-landingpage .logo-p-only {
    height: 37.01px;
    margin-left: -11.00px;
    object-fit: cover;
    position: relative;
    width: 38px;
  }

  .web-landingpage .text-wrapper-3 {
    color: var(--primary);
    font-family: "Roboto", Helvetica;
    font-size: 14px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 21px;
    position: relative;
    width: 190px;
    align-self: center;
  }

  .web-landingpage .column {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 10px;
    position: relative;
  }

  .web-landingpage .style-primary-small-true-alternate-false-icon-position-no-icon {
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
    display: flex !important;
    height: 36px !important;
    width: 69px !important;
  }

  .web-landingpage .style-primary-small-instance {
    font-family: "Roboto", Helvetica !important;
    font-size: 16px !important;
    font-style: unset !important;
    font-weight: 400 !important;
    letter-spacing: 0 !important;
    line-height: 24px !important;
    margin-left: -1.00px !important;
    margin-top: -1.00px !important;
  }

  .web-landingpage .actions {
    flex: 1;
    flex-grow: 1;
    margin-right: -13.00px;
    position: relative;
  }

  .web-landingpage .header {
    align-items: flex-start;
    background-color: var(--semantic-background-color-primary);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 80px;
    padding: 0px 20px;
    position: relative;
    width: 375px;
  }

  .web-landingpage .div-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
    width: 100%;
  }

  .web-landingpage .column-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 24px;
    padding: 64px 0px;
    position: relative;
    width: 100%;
  }

  .web-landingpage .content {
    margin-top: 60px;
    overflow: hidden;
    position: relative;
    width: 335px;
  }

  .web-landingpage .content-pic {
    margin-top: 60px;
    overflow: hidden;
    position: relative;
    width: 335px;
  }

  .web-landingpage .div-content {
    gap: 48px;
    padding: 64px 20px;
    width: 375px;
  }

  .web-landingpage .div-3 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 20px;
    position: relative;
    width: 100%;
  }


  .web-landingpage .content-box {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 20px;
    position: relative;
    width: 100%;
  }

  .web-landingpage .medium-length-hero {
    align-self: stretch;
    color: transparent;
    font-family: var(--heading-mobile-h1-font-family);
    font-size: var(--heading-mobile-h1-font-size);
    font-style: var(--heading-mobile-h1-font-style);
    font-weight: var(--heading-mobile-h1-font-weight);
    letter-spacing: var(--heading-mobile-h1-letter-spacing);
    line-height: var(--heading-mobile-h1-line-height);
    margin-top: -1.00px;
    position: relative;
  }

  .web-landingpage .span {
    color: #000000;
    font-family: var(--heading-mobile-h1-font-family);
    font-size: var(--heading-mobile-h1-font-size);
    font-style: var(--heading-mobile-h1-font-style);
    font-weight: var(--heading-mobile-h1-font-weight);
    letter-spacing: var(--heading-mobile-h1-letter-spacing);
    line-height: var(--heading-mobile-h1-line-height);
  }

  .web-landingpage .text-wrapper-4 {
    color: #7be0ad;
    font-family: var(--heading-mobile-h1-font-family);
    font-size: var(--heading-mobile-h1-font-size);
    font-style: var(--heading-mobile-h1-font-style);
    font-weight: var(--heading-mobile-h1-font-weight);
    letter-spacing: var(--heading-mobile-h1-letter-spacing);
    line-height: var(--heading-mobile-h1-line-height);
  }

  .web-landingpage .p {
    align-self: stretch;
    color: var(--semantic-text-primary);
    font-family: var(--text-regular-normal-font-family);
    font-size: var(--text-regular-normal-font-size);
    font-style: var(--text-regular-normal-font-style);
    font-weight: var(--text-regular-normal-font-weight);
    letter-spacing: var(--text-regular-normal-letter-spacing);
    line-height: var(--text-regular-normal-line-height);
    position: relative;
  }

  .web-landingpage .style-primary-small-false-alternate-false-icon-position-no-icon {
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
    display: flex !important;
    height: 44px !important;
    margin-bottom: -2.00px !important;
    margin-left: -2.00px !important;
    width: 199.92px !important;
  }

  .web-landingpage .instance-node {
    font-family: "Roboto", Helvetica !important;
    font-size: 16px !important;
    font-style: unset !important;
    font-weight: 400 !important;
    letter-spacing: 0 !important;
    line-height: 24px !important;
    margin-left: -1.04px !important;
    margin-top: -5.00px !important;
  }



  .web-landingpage .pic-col-left {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 16px;
    left: 176px;
    position: relative;
    top: -2px;
  }

  .web-landingpage .placeholder-image {
    height: 168.16px;
    object-fit: cover;
    position: relative;
    width: 159px;
  }

  .web-landingpage .img {
    height: 170px;
    object-fit: cover;
    position: relative;
    width: 159px;
  }

  .web-landingpage .placeholder-image-2 {
    height: 90.15px;
    object-fit: cover;
    position: relative;
    width: 159px;
  }

  .web-landingpage .pic-col-right {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 16px;
    left: 0;
    position: absolute;
  }

  .web-landingpage .placeholder-image-3 {
    height: 92.26px;
    object-fit: cover;
    position: relative;
    width: 160px;
  }

  .web-landingpage .placeholder-image-4 {
    height: 170px;
    object-fit: cover;
    position: relative;
    width: 160px;
  }

  .web-landingpage .placeholder-image-5 {
    height: 166.05px;
    object-fit: cover;
    position: relative;
    width: 160px;
  }

  .web-landingpage .div-4 {
    align-items: center;
    background-color: var(--semantic-background-color-primary);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 48px;
    padding: 64px 20px;
    position: relative;
    width: 375px;
  }

  .web-landingpage .heading {
    align-self: stretch;
    color: transparent;
    font-family: var(--heading-mobile-h3-font-family);
    font-size: var(--heading-mobile-h3-font-size);
    font-style: var(--heading-mobile-h3-font-style);
    font-weight: var(--heading-mobile-h3-font-weight);
    letter-spacing: var(--heading-mobile-h3-letter-spacing);
    line-height: var(--heading-mobile-h3-line-height);
    margin-top: -1.00px;
    position: relative;
    text-align: center;
  }

  .web-landingpage .text-wrapper-5 {
    color: #000000;
    font-family: var(--heading-mobile-h3-font-family);
    font-size: var(--heading-mobile-h3-font-size);
    font-style: var(--heading-mobile-h3-font-style);
    font-weight: var(--heading-mobile-h3-font-weight);
    letter-spacing: var(--heading-mobile-h3-letter-spacing);
    line-height: var(--heading-mobile-h3-line-height);
  }

  .web-landingpage .text-wrapper-6 {
    color: #7be0ad;
    font-family: var(--heading-mobile-h3-font-family);
    font-size: var(--heading-mobile-h3-font-size);
    font-style: var(--heading-mobile-h3-font-style);
    font-weight: var(--heading-mobile-h3-font-weight);
    letter-spacing: var(--heading-mobile-h3-letter-spacing);
    line-height: var(--heading-mobile-h3-line-height);
  }

  .web-landingpage .row {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 48px;
    position: relative;
    width: 100%;
  }

  .web-landingpage .arg-point {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 24px;
    position: relative;
    width: 100%;
  }

  .web-landingpage .div-5 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 24px;
    position: relative;
    width: 100%;
  }

  .footer-div {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 24px;
    position: relative;
    width: 100%;
  }

  .web-landingpage .section-title {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 20px;
    position: relative;
    width: 100%;
  }

  .web-landingpage .icon-relume {
    height: 48px !important;
    position: relative !important;
    width: 48px !important;
  }

  .web-landingpage .heading-2 {
    align-self: stretch;
    color: var(--semantic-text-primary);
    font-family: var(--heading-mobile-h5-font-family);
    font-size: var(--heading-mobile-h5-font-size);
    font-style: var(--heading-mobile-h5-font-style);
    font-weight: var(--heading-mobile-h5-font-weight);
    letter-spacing: var(--heading-mobile-h5-letter-spacing);
    line-height: var(--heading-mobile-h5-line-height);
    margin-top: -1.00px;
    position: relative;
    text-align: center;
  }

  .web-landingpage .text {
    align-self: stretch;
    color: var(--semantic-text-primary);
    font-family: var(--text-regular-normal-font-family);
    font-size: var(--text-regular-normal-font-size);
    font-style: var(--text-regular-normal-font-style);
    font-weight: var(--text-regular-normal-font-weight);
    letter-spacing: var(--text-regular-normal-letter-spacing);
    line-height: var(--text-regular-normal-line-height);
    position: relative;
    text-align: center;
  }

  .web-landingpage .action {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    position: relative;
    width: 100%;
  }

  .web-landingpage .style-link-small {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    position: relative;
  }

  .web-landingpage .container-wrapper {
    align-items: flex-start;
    background-color: var(--semantic-background-color-primary);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 48px;
    padding: 64px 20px;
    position: relative;
    width: 375px;
  }

  .web-landingpage .div-6 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 12px;
    position: relative;
    width: 100%;
  }

  .web-landingpage .container-wrapper-box {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 48px;
    position: relative;
    width: 100%;
  }

  .web-landingpage .div-box {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 24px;
    position: relative;
    width: 100%;
  }

  .web-landingpage .tagline-wrapper {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    position: relative;
  }

  .web-landingpage .tagline {
    color: var(--semantic-text-primary);
    font-family: var(--heading-desktop-tagline-font-family);
    font-size: var(--heading-desktop-tagline-font-size);
    font-style: var(--heading-desktop-tagline-font-style);
    font-weight: var(--heading-desktop-tagline-font-weight);
    letter-spacing: var(--heading-desktop-tagline-letter-spacing);
    line-height: var(--heading-desktop-tagline-line-height);
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .web-landingpage .heading-3 {
    align-self: stretch;
    color: transparent;
    font-family: var(--heading-mobile-h2-font-family);
    font-size: var(--heading-mobile-h2-font-size);
    font-style: var(--heading-mobile-h2-font-style);
    font-weight: var(--heading-mobile-h2-font-weight);
    letter-spacing: var(--heading-mobile-h2-letter-spacing);
    line-height: var(--heading-mobile-h2-line-height);
    margin-top: -1.00px;
    position: relative;
  }

  .web-landingpage .text-wrapper-7 {
    color: #7be0ad;
    font-family: var(--heading-mobile-h2-font-family);
    font-size: var(--heading-mobile-h2-font-size);
    font-style: var(--heading-mobile-h2-font-style);
    font-weight: var(--heading-mobile-h2-font-weight);
    letter-spacing: var(--heading-mobile-h2-letter-spacing);
    line-height: var(--heading-mobile-h2-line-height);
  }

  .web-landingpage .text-wrapper-8 {
    color: #000000;
    font-family: var(--heading-mobile-h2-font-family);
    font-size: var(--heading-mobile-h2-font-size);
    font-style: var(--heading-mobile-h2-font-style);
    font-weight: var(--heading-mobile-h2-font-weight);
    letter-spacing: var(--heading-mobile-h2-letter-spacing);
    line-height: var(--heading-mobile-h2-line-height);
  }

  .web-landingpage .instance-node-2 {
    font-family: "Roboto", Helvetica !important;
    font-size: 16px !important;
    font-style: unset !important;
    font-weight: 400 !important;
    letter-spacing: 0 !important;
    line-height: 24px !important;
    margin-left: -11.54px !important;
    margin-right: -5.54px !important;
    margin-top: -5.00px !important;
  }

  .web-landingpage .placeholder-image-half {
    align-self: stretch;
    height: 348px;
    object-fit: cover;
    position: relative;
    width: 100%;
  }

  .web-landingpage .layout {
    align-items: flex-start;
    background-color: var(--secondary);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 48px;
    padding: 64px 28px;
    position: relative;
    width: 375px;
  }

  .web-landingpage .text-wrapper-9 {
    align-self: stretch;
    color: var(--semantic-text-primary);
    font-family: var(--heading-mobile-h2-font-family);
    font-size: var(--heading-mobile-h2-font-size);
    font-style: var(--heading-mobile-h2-font-style);
    font-weight: var(--heading-mobile-h2-font-weight);
    letter-spacing: var(--heading-mobile-h2-letter-spacing);
    line-height: var(--heading-mobile-h2-line-height);
    margin-top: -1.00px;
    position: relative;
  }

  .web-landingpage .list {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 24px;
    padding: 8px 0px;
    position: relative;
    width: 100%;
  }

  .web-landingpage .list-item {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    position: relative;
    width: 100%;
  }

  .web-landingpage .logo-color-dark {
    height: 48px !important;
    position: relative !important;
    width: 120px !important;
  }

  .web-landingpage .quote {
    align-self: stretch;
    color: var(--semantic-text-primary);
    font-family: var(--heading-mobile-h5-font-family);
    font-size: var(--heading-mobile-h5-font-size);
    font-style: var(--heading-mobile-h5-font-style);
    font-weight: var(--heading-mobile-h5-font-weight);
    letter-spacing: var(--heading-mobile-h5-letter-spacing);
    line-height: var(--heading-mobile-h5-line-height);
    position: relative;
    text-align: center;
  }

  .web-landingpage .avatar {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 16px;
    position: relative;
    width: 300px;
  }

  .web-landingpage .avatar-image {
    height: 64px;
    object-fit: cover;
    position: relative;
    width: 64px;
  }

  .web-landingpage .text-2 {
    align-self: stretch;
    color: var(--semantic-text-primary);
    font-family: var(--text-regular-semi-bold-font-family);
    font-size: var(--text-regular-semi-bold-font-size);
    font-style: var(--text-regular-semi-bold-font-style);
    font-weight: var(--text-regular-semi-bold-font-weight);
    letter-spacing: var(--text-regular-semi-bold-letter-spacing);
    line-height: var(--text-regular-semi-bold-line-height);
    margin-top: -1.00px;
    position: relative;
    text-align: center;
  }

  .web-landingpage .instance-node-3 {
    font-family: "Roboto", Helvetica !important;
    font-size: 16px !important;
    font-style: unset !important;
    font-weight: 400 !important;
    letter-spacing: 0 !important;
    line-height: 24px !important;
    margin-left: -6.54px !important;
    margin-right: -0.54px !important;
    margin-top: -5.00px !important;
  }

  .web-landingpage .placeholder-image-box {
    align-self: stretch;
    height: 218px;
    object-fit: cover;
    position: relative;
    width: 100%;
  }

  .footer {
    align-items: flex-start;
    background-color: var(--secondary);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 48px;
    padding: 48px 20px;
    position: relative;
    width: 100%;
  }

  .footer-last-line {
    flex-direction: column;
    gap: 20px;
  }

  .footer-div-5 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 12px;
    position: relative;
    width: 100%;
  }

  .web-landingpage .logo-p-only-2 {
    height: 40.91px;
    object-fit: cover;
    position: relative;
    width: 42px;
  }

  .web-landingpage .div-9 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 16px;
    position: relative;
    width: 100%;
  }


  .web-landingpage .instance-node-4 {
    font-family: "Roboto", Helvetica !important;
    font-size: 16px !important;
    font-style: unset !important;
    font-weight: 400 !important;
    letter-spacing: 0 !important;
    line-height: 24px !important;
  }

  .style-secondary-small-false-alternate-false-icon-position-no-icon {
    display: flex !important;
    margin-bottom: -1.00px !important;
    margin-right: -1.00px !important;
    margin-top: -1.00px !important;
    width: 121px !important;
  }

  .web-landingpage .by-subscribing-you {
    align-self: stretch;
    color: var(--semantic-text-primary);
    font-family: var(--text-tiny-normal-font-family);
    font-size: var(--text-tiny-normal-font-size);
    font-style: var(--text-tiny-normal-font-style);
    font-weight: var(--text-tiny-normal-font-weight);
    letter-spacing: var(--text-tiny-normal-letter-spacing);
    line-height: var(--text-tiny-normal-line-height);
    position: relative;
  }

  .web-landingpage .links {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 40px;
    position: relative;
    width: 100%;
  }

  .footer-links-title {
    align-self: stretch;
    color: var(--semantic-text-primary);
    font-family: var(--text-regular-semi-bold-font-family);
    font-size: var(--text-regular-semi-bold-font-size);
    font-style: var(--text-regular-semi-bold-font-style);
    font-weight: var(--text-regular-semi-bold-font-weight);
    letter-spacing: var(--text-regular-semi-bold-letter-spacing);
    line-height: var(--text-regular-semi-bold-line-height);
    margin-top: -1.00px;
    position: relative;
  }

  .web-landingpage .link {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    padding: 8px 0px;
    position: relative;
    width: 100%;
  }

  .web-landingpage .text-wrapper-11 {
    color: var(--semantic-link-primary);
    flex: 1;
    font-family: var(--text-small-normal-font-family);
    font-size: var(--text-small-normal-font-size);
    font-style: var(--text-small-normal-font-style);
    font-weight: var(--text-small-normal-font-weight);
    letter-spacing: var(--text-small-normal-letter-spacing);
    line-height: var(--text-small-normal-line-height);
    margin-top: -1.00px;
    position: relative;
  }

  .footer-link-instance {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 12px;
    padding: 8px 0px;
    position: relative;
    width: 100%;
  }

  .web-landingpage .instance-node-5 {
    height: 24px !important;
    position: relative !important;
    width: 24px !important;
  }

  .web-landingpage .text-wrapper-12 {
    color: var(--semantic-link-primary);
    font-family: var(--text-small-normal-font-family);
    font-size: var(--text-small-normal-font-size);
    font-style: var(--text-small-normal-font-style);
    font-weight: var(--text-small-normal-font-weight);
    letter-spacing: var(--text-small-normal-letter-spacing);
    line-height: var(--text-small-normal-line-height);
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .web-landingpage .row-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 32px;
    padding: 0px 0px 16px;
    position: relative;
    width: 100%;
  }

  .web-landingpage .text-wrapper-13 {
    color: var(--semantic-link-primary);
    font-family: var(--text-small-link-font-family);
    font-size: var(--text-small-link-font-size);
    font-style: var(--text-small-link-font-style);
    font-weight: var(--text-small-link-font-weight);
    letter-spacing: var(--text-small-link-letter-spacing);
    line-height: var(--text-small-link-line-height);
    margin-top: -1.00px;
    position: relative;
    text-decoration: underline;
    white-space: nowrap;
    width: fit-content;
  }

  .web-landingpage .text-wrapper-14 {
    color: var(--semantic-link-primary);
    font-family: var(--text-small-link-font-family);
    font-size: var(--text-small-link-font-size);
    font-style: var(--text-small-link-font-style);
    font-weight: var(--text-small-link-font-weight);
    letter-spacing: var(--text-small-link-letter-spacing);
    line-height: var(--text-small-link-line-height);
    position: relative;
    text-decoration: underline;
    white-space: nowrap;
    width: fit-content;
  }

  .web-landingpage .element-relume-all-2 {
    color: var(--semantic-text-primary);
    font-family: var(--text-small-normal-font-family);
    font-size: var(--text-small-normal-font-size);
    font-style: var(--text-small-normal-font-style);
    font-weight: var(--text-small-normal-font-weight);
    letter-spacing: var(--text-small-normal-letter-spacing);
    line-height: var(--text-small-normal-line-height);
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .footer-div-wrapper {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    padding: 8px 0px;
    position: relative;
    width: 100%;
  }

  .web-landingpage .text-wrapper-15 {
    color: var(--primary);
    font-family: "Roboto", Helvetica;
    font-size: 20px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 30px;
    position: relative;
    width: 190px;
  }

  .web-landingpage .column-wrapper {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 529px;
  }

  .web-landingpage .div-10 {
    flex: 0 0 auto;
    position: relative;
  }

  .web-landingpage .nav-link-dropdown-wrapper {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    width: 102px;
  }

  .web-landingpage .nav-link-dropdown {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 4px;
    justify-content: center;
    position: relative;
    width: 100%;
  }

  .web-landingpage .style-primary-small-true-alternate-false-icon-position-no-icon-wrapper {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 529px;
  }

  .web-landingpage .style-primary-small-true-alternate-false-icon-position-no-icon-instance {
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
    flex: 0 0 auto !important;
    margin-bottom: -2.00px !important;
    margin-right: -2.00px !important;
    margin-top: -2.00px !important;
  }

  .web-landingpage .style-primary-small-2 {
    font-family: "Roboto", Helvetica !important;
    font-size: 16px !important;
    font-style: unset !important;
    font-weight: 400 !important;
    letter-spacing: 0 !important;
    line-height: 24px !important;
    margin-left: -1.00px !important;
    margin-top: -1.00px !important;
  }

  .web-landingpage .header-2 {
    align-items: center;
    background-color: var(--semantic-background-color-primary);
    display: flex;
    flex-direction: column;
    gap: 80px;
    height: 900px;
    padding: 0px 64px;
    position: relative;
    width: 1440px;
  }

  .web-landingpage .container-3 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-grow: 1;
    position: relative;
    width: 100%;
  }

  .footer-links-column {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 32px;
    justify-content: center;
    padding: 0px 80px 0px 0px;
    position: relative;
  }

  .web-landingpage .medium-length-hero-2 {
    align-self: stretch;
    color: transparent;
    font-family: var(--heading-desktop-h1-font-family);
    font-size: var(--heading-desktop-h1-font-size);
    font-style: var(--heading-desktop-h1-font-style);
    font-weight: var(--heading-desktop-h1-font-weight);
    letter-spacing: var(--heading-desktop-h1-letter-spacing);
    line-height: var(--heading-desktop-h1-line-height);
    margin-top: -1.00px;
    position: relative;
  }

  .web-landingpage .text-wrapper-16 {
    color: #000000;
    font-family: var(--heading-desktop-h1-font-family);
    font-size: var(--heading-desktop-h1-font-size);
    font-style: var(--heading-desktop-h1-font-style);
    font-weight: var(--heading-desktop-h1-font-weight);
    letter-spacing: var(--heading-desktop-h1-letter-spacing);
    line-height: var(--heading-desktop-h1-line-height);
  }

  .web-landingpage .text-wrapper-17 {
    color: #7be0ad;
    font-family: var(--heading-desktop-h1-font-family);
    font-size: var(--heading-desktop-h1-font-size);
    font-style: var(--heading-desktop-h1-font-style);
    font-weight: var(--heading-desktop-h1-font-weight);
    letter-spacing: var(--heading-desktop-h1-letter-spacing);
    line-height: var(--heading-desktop-h1-line-height);
  }

  .web-landingpage .text-wrapper-18 {
    align-self: stretch;
    color: var(--semantic-text-primary);
    font-family: var(--text-medium-normal-font-family);
    font-size: var(--text-medium-normal-font-size);
    font-style: var(--text-medium-normal-font-style);
    font-weight: var(--text-medium-normal-font-weight);
    letter-spacing: var(--text-medium-normal-letter-spacing);
    line-height: var(--text-medium-normal-line-height);
    position: relative;
  }

  .web-landingpage .buttons-instance {
    position: relative !important;
  }

  .web-landingpage .buttons-2 {
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
    display: flex !important;
    height: 44px !important;
    left: -2px !important;
    top: -2px !important;
    width: 200px !important;
  }

  .web-landingpage .content-3 {
    flex: 1;
    flex-grow: 1;
    height: 900px;
    position: relative;
  }

  .web-landingpage .column-7 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 2732px;
    left: 337px;
    position: absolute;
    top: 0;
    width: 319px;
  }

  .web-landingpage .placeholder-image-8 {
    align-self: stretch;
    height: 340px;
    object-fit: cover;
    position: relative;
    width: 100%;
  }

  .web-landingpage .placeholder-image-9 {
    align-self: stretch;
    height: 188px;
    object-fit: cover;
    position: relative;
    width: 100%;
  }

  .web-landingpage .column-8 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 2732px;
    left: 0;
    position: absolute;
    top: -152px;
    width: 319px;
  }

  .web-landingpage .div-11 {
    align-items: center;
    background-color: var(--semantic-background-color-primary);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 80px;
    padding: 112px 64px;
    position: relative;
    width: 1440px;
  }

  .web-landingpage .heading-4 {
    color: transparent;
    font-family: var(--heading-desktop-h3-font-family);
    font-size: var(--heading-desktop-h3-font-size);
    font-style: var(--heading-desktop-h3-font-style);
    font-weight: var(--heading-desktop-h3-font-weight);
    letter-spacing: var(--heading-desktop-h3-letter-spacing);
    line-height: var(--heading-desktop-h3-line-height);
    margin-top: -1.00px;
    position: relative;
    text-align: center;
    width: 768px;
  }

  .web-landingpage .text-wrapper-19 {
    color: #000000;
    font-family: var(--heading-desktop-h3-font-family);
    font-size: var(--heading-desktop-h3-font-size);
    font-style: var(--heading-desktop-h3-font-style);
    font-weight: var(--heading-desktop-h3-font-weight);
    letter-spacing: var(--heading-desktop-h3-letter-spacing);
    line-height: var(--heading-desktop-h3-line-height);
  }

  .web-landingpage .text-wrapper-20 {
    color: #7be0ad;
    font-family: var(--heading-desktop-h3-font-family);
    font-size: var(--heading-desktop-h3-font-size);
    font-style: var(--heading-desktop-h3-font-style);
    font-weight: var(--heading-desktop-h3-font-weight);
    letter-spacing: var(--heading-desktop-h3-letter-spacing);
    line-height: var(--heading-desktop-h3-line-height);
  }

  .web-landingpage .row-wrapper {}

  .web-landingpage .row-3 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 32px;
    justify-content: center;
    position: relative;
    width: 100%;
  }

  .web-landingpage .content-4 {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 32px;
    position: relative;
  }

  .web-landingpage .content-5 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 20px;
    position: relative;
    width: 100%;
  }

  .web-landingpage .heading-5 {
    align-self: stretch;
    color: var(--black);
    font-family: var(--heading-desktop-h5-font-family);
    font-size: var(--heading-desktop-h5-font-size);
    font-style: var(--heading-desktop-h5-font-style);
    font-weight: var(--heading-desktop-h5-font-weight);
    letter-spacing: var(--heading-desktop-h5-letter-spacing);
    line-height: var(--heading-desktop-h5-line-height);
    margin-top: -1.00px;
    position: relative;
    text-align: center;
  }

  .web-landingpage .container-wrapper-2 {
    align-items: flex-start;
    background-color: var(--semantic-background-color-primary);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 80px;
    padding: 112px 64px;
    position: relative;
    width: 1440px;
  }

  .web-landingpage .container-4 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 80px;
    position: relative;
    width: 100%;
  }

  .web-landingpage .div-12 {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 32px;
    position: relative;
  }

  .web-landingpage .div-13 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 32px;
    position: relative;
    width: 100%;
  }

  .web-landingpage .heading-6 {
    align-self: stretch;
    color: transparent;
    font-family: var(--heading-desktop-h2-font-family);
    font-size: var(--heading-desktop-h2-font-size);
    font-style: var(--heading-desktop-h2-font-style);
    font-weight: var(--heading-desktop-h2-font-weight);
    letter-spacing: var(--heading-desktop-h2-letter-spacing);
    line-height: var(--heading-desktop-h2-line-height);
    margin-top: -1.00px;
    position: relative;
  }

  .web-landingpage .text-wrapper-21 {
    color: #7be0ad;
    font-family: var(--heading-desktop-h2-font-family);
    font-size: var(--heading-desktop-h2-font-size);
    font-style: var(--heading-desktop-h2-font-style);
    font-weight: var(--heading-desktop-h2-font-weight);
    letter-spacing: var(--heading-desktop-h2-letter-spacing);
    line-height: var(--heading-desktop-h2-line-height);
  }

  .web-landingpage .text-wrapper-22 {
    color: #000000;
    font-family: var(--heading-desktop-h2-font-family);
    font-size: var(--heading-desktop-h2-font-size);
    font-style: var(--heading-desktop-h2-font-style);
    font-weight: var(--heading-desktop-h2-font-weight);
    letter-spacing: var(--heading-desktop-h2-letter-spacing);
    line-height: var(--heading-desktop-h2-line-height);
  }

  .web-landingpage .placeholder-image-10 {
    flex: 1;
    flex-grow: 1;
    height: 640px;
    object-fit: cover;
    position: relative;
  }

  .web-landingpage .content-6 {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 24px;
    position: relative;
  }

  .web-landingpage .heading-7 {
    align-self: stretch;
    color: transparent;
    font-family: var(--heading-desktop-h3-font-family);
    font-size: var(--heading-desktop-h3-font-size);
    font-style: var(--heading-desktop-h3-font-style);
    font-weight: var(--heading-desktop-h3-font-weight);
    letter-spacing: var(--heading-desktop-h3-letter-spacing);
    line-height: var(--heading-desktop-h3-line-height);
    margin-top: -1.00px;
    position: relative;
  }

  .web-landingpage .layout-2 {
    align-items: flex-start;
    background-color: var(--secondary);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 80px;
    padding: 112px 64px;
    position: relative;
    width: 1440px;
  }

  .web-landingpage .heading-8 {
    align-self: stretch;
    color: var(--semantic-text-primary);
    font-family: var(--heading-desktop-h3-font-family);
    font-size: var(--heading-desktop-h3-font-size);
    font-style: var(--heading-desktop-h3-font-style);
    font-weight: var(--heading-desktop-h3-font-weight);
    letter-spacing: var(--heading-desktop-h3-letter-spacing);
    line-height: var(--heading-desktop-h3-line-height);
    margin-top: -1.00px;
    position: relative;
  }

  .web-landingpage .row-4 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 24px;
    padding: 8px 0px;
    position: relative;
    width: 100%;
  }

  .web-landingpage .list-item-2 {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 8px;
    position: relative;
  }

  .web-landingpage .number {
    align-self: stretch;
    color: var(--semantic-text-primary);
    font-family: var(--heading-desktop-h2-font-family);
    font-size: var(--heading-desktop-h2-font-size);
    font-style: var(--heading-desktop-h2-font-style);
    font-weight: var(--heading-desktop-h2-font-weight);
    letter-spacing: var(--heading-desktop-h2-letter-spacing);
    line-height: var(--heading-desktop-h2-line-height);
    margin-top: -1.00px;
    position: relative;
  }

  .footer-content {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 48px;
    position: relative;
    width: 100%;
  }

  .web-landingpage .quote-2 {
    align-self: stretch;
    color: var(--semantic-text-primary);
    font-family: var(--heading-desktop-h5-font-family);
    font-size: var(--heading-desktop-h5-font-size);
    font-style: var(--heading-desktop-h5-font-style);
    font-weight: var(--heading-desktop-h5-font-weight);
    letter-spacing: var(--heading-desktop-h5-letter-spacing);
    line-height: var(--heading-desktop-h5-line-height);
    position: relative;
    text-align: center;
  }

  .web-landingpage .placeholder-image-11 {
    height: 600px;
    object-fit: cover;
    position: relative;
    width: 640px;
  }


  .web-landingpage .content-8 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    gap: 332px;
    height: 248px;
    position: relative;
    width: 100%;
  }

  .footer-newsletter {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 20px;
    position: relative;
    width: 100%;
  }

  .type-default-instance {
    flex: 1 !important;
    flex-grow: 1 !important;
    margin-bottom: -1.00px !important;
    margin-left: -1.00px !important;
    margin-top: -1.00px !important;
    width: unset !important;
  }

  .web-landingpage .style-secondary-instance {
    display: flex !important;
    margin-bottom: -1.00px !important;
    margin-right: -1.00px !important;
    margin-top: -1.00px !important;
    width: 121px !important;
  }

  .web-landingpage .links-2 {
    align-items: flex-start;
    display: flex;
    gap: 70px;
    margin-right: -130.00px;
    position: relative;
    width: 610px;
  }

  .web-landingpage .column-9 {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 16px;
    position: relative;
  }

}