.web-listing-v {
    display: flex;
    flex-direction: column;
}

.web-listing {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.web-listing .web-home {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    position: relative;
    margin: 0px auto;
}

.web-listing .icon-user-2 {
    height: 40px !important;
    margin-right: -1.00px !important;
    position: relative !important;
    width: 40px !important;
}

.web-listing .loading {
    align-items: flex-start;
    background-color: var(--semantic-background-color-primary);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 80px;
    padding: 112px 64px;
    position: relative;
    width: 1440px;
}

.web-listing .container-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100%;
}

.web-listing .div-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 32px;
    position: relative;
    width: 100%;
}

.web-listing .div-3 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 16px;
    position: relative;
    width: 100%;
}

.web-listing .heading-wrapper {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 24px;
    position: relative;
    width: 100%;
}

.web-listing .heading {
    align-self: stretch;
    color: transparent;
    font-family: var(--heading-desktop-h2-font-family);
    font-size: var(--heading-desktop-h2-font-size);
    font-style: var(--heading-desktop-h2-font-style);
    font-weight: var(--heading-desktop-h2-font-weight);
    letter-spacing: var(--heading-desktop-h2-letter-spacing);
    line-height: var(--heading-desktop-h2-line-height);
    margin-top: -1.00px;
    position: relative;
    text-align: center;
}

.web-listing .span {
    color: #7be0ad;
    font-family: var(--heading-desktop-h2-font-family);
    font-size: var(--heading-desktop-h2-font-size);
    font-style: var(--heading-desktop-h2-font-style);
    font-weight: var(--heading-desktop-h2-font-weight);
    letter-spacing: var(--heading-desktop-h2-letter-spacing);
    line-height: var(--heading-desktop-h2-line-height);
}

.web-listing .text-wrapper-5 {
    color: #000000;
    font-family: var(--heading-desktop-h2-font-family);
    font-size: var(--heading-desktop-h2-font-size);
    font-style: var(--heading-desktop-h2-font-style);
    font-weight: var(--heading-desktop-h2-font-weight);
    letter-spacing: var(--heading-desktop-h2-letter-spacing);
    line-height: var(--heading-desktop-h2-line-height);
}

.web-listing .buttons {
    height: 40px;
    position: relative;
    width: 196px;
}

.web-listing .placeholder-image {
    align-self: stretch;
    height: 640px;
    object-fit: cover;
    position: relative;
    width: 100%;
}

.web-listing .list {
    align-items: center;
    background-color: var(--semantic-text-alternate);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 80px;
    padding: 0px 64px;
    position: relative;
    width: 1440px;
}

.web-listing .container-3 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 40px;
    justify-content: center;
    padding: 20px 0px;
    position: relative;
    width: 100%;
}

.web-listing .section-title {
    align-items: flex-start;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 16px;
    position: relative;
    width: 1312px;
}

.web-listing .content-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    gap: 332px;
    height: 248px;
    position: relative;
    width: 100%;
}

.web-listing .newsletter {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;
    width: 500px;
}

.web-listing .img {
    height: 40.91px;
    object-fit: cover;
    position: relative;
    width: 42px;
}

.web-listing .join-our-newsletter {
    align-self: stretch;
    color: var(--semantic-text-primary);
    font-family: var(--text-regular-normal-font-family);
    font-size: var(--text-regular-normal-font-size);
    font-style: var(--text-regular-normal-font-style);
    font-weight: var(--text-regular-normal-font-weight);
    letter-spacing: var(--text-regular-normal-letter-spacing);
    line-height: var(--text-regular-normal-line-height);
    position: relative;
}



.web-listing .design-component-instance-node {
    font-family: "Roboto", Helvetica !important;
    font-size: 16px !important;
    font-style: unset !important;
    font-weight: 400 !important;
    letter-spacing: 0 !important;
    line-height: 24px !important;
}


.web-listing .by-subscribing-you {
    align-self: stretch;
    color: var(--semantic-text-primary);
    font-family: var(--text-tiny-normal-font-family);
    font-size: var(--text-tiny-normal-font-size);
    font-style: var(--text-tiny-normal-font-style);
    font-weight: var(--text-tiny-normal-font-weight);
    letter-spacing: var(--text-tiny-normal-letter-spacing);
    line-height: var(--text-tiny-normal-line-height);
    position: relative;
}

.web-listing .column-3 {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 16px;
    position: relative;
}

.web-listing .text-wrapper-6 {
    align-self: stretch;
    color: var(--semantic-text-primary);
    font-family: var(--text-regular-semi-bold-font-family);
    font-size: var(--text-regular-semi-bold-font-size);
    font-style: var(--text-regular-semi-bold-font-style);
    font-weight: var(--text-regular-semi-bold-font-weight);
    letter-spacing: var(--text-regular-semi-bold-letter-spacing);
    line-height: var(--text-regular-semi-bold-line-height);
    margin-top: -1.00px;
    position: relative;
}

.web-listing .div-4 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
    width: 100%;
}

.web-listing .text-wrapper-7 {
    color: var(--semantic-link-primary);
    flex: 1;
    font-family: var(--text-small-normal-font-family);
    font-size: var(--text-small-normal-font-size);
    font-style: var(--text-small-normal-font-style);
    font-weight: var(--text-small-normal-font-weight);
    letter-spacing: var(--text-small-normal-letter-spacing);
    line-height: var(--text-small-normal-line-height);
    margin-top: -1.00px;
    position: relative;
}

.web-listing .link-3 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 12px;
    padding: 8px 0px;
    position: relative;
    width: 100%;
}

.web-listing .icon-instance-node {
    height: 24px !important;
    position: relative !important;
    width: 24px !important;
}

.web-listing .text-wrapper-8 {
    color: var(--semantic-link-primary);
    font-family: var(--text-small-normal-font-family);
    font-size: var(--text-small-normal-font-size);
    font-style: var(--text-small-normal-font-style);
    font-weight: var(--text-small-normal-font-weight);
    letter-spacing: var(--text-small-normal-letter-spacing);
    line-height: var(--text-small-normal-line-height);
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.web-listing .divider {
    align-self: stretch;
    background-color: var(--semantic-border-primary);
    border: 1px solid;
    border-color: var(--semantic-border-primary);
    height: 1px;
    position: relative;
    width: 100%;
}

.web-listing .rights-impressum-instance {
    flex: 0 0 auto !important;
}

.listing {
    align-items: center;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 0px 20px #00000040;
    display: flex;
    gap: 62px;
    height: 434px;
    padding: 0px 20px;
    position: relative;
    width: 1312px;
}

.listing .side {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 62px;
    justify-content: center;
    position: relative;
    width: 280px;
}

.listing .element {
    color: #000000;
    font-family: var(--heading-desktop-h3-font-family);
    font-size: var(--heading-desktop-h3-font-size);
    font-style: var(--heading-desktop-h3-font-style);
    font-weight: var(--heading-desktop-h3-font-weight);
    letter-spacing: var(--heading-desktop-h3-letter-spacing);
    line-height: var(--heading-desktop-h3-line-height);
    margin-top: -1.00px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
}

.listing .frame {
    align-items: center;
    align-self: stretch;
    background-color: var(--secondarybright);
    border-radius: 10px;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    padding: 5px 20px;
    position: relative;
    width: 100%;
}

.listing .new {
    color: #000000;
    font-family: var(--heading-desktop-h5-font-family);
    font-size: var(--heading-desktop-h5-font-size);
    font-style: var(--heading-desktop-h5-font-style);
    font-weight: var(--heading-desktop-h5-font-weight);
    letter-spacing: var(--heading-desktop-h5-letter-spacing);
    line-height: var(--heading-desktop-h5-line-height);
    margin-top: -1.00px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
}

.listing .path {
    height: 7.21px;
    margin-right: -0.50px;
    position: relative;
    width: 13px;
}

.listing .content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 689px;
}

.listing .p {
    align-self: stretch;
    color: #000000;
    font-family: var(--heading-desktop-h5-font-family);
    font-size: var(--heading-desktop-h5-font-size);
    font-style: var(--heading-desktop-h5-font-style);
    font-weight: var(--heading-desktop-h5-font-weight);
    letter-spacing: var(--heading-desktop-h5-letter-spacing);
    line-height: var(--heading-desktop-h5-line-height);
    margin-top: -1.00px;
    position: relative;
}

.listing .text-wrapper-2 {
    align-self: stretch;
    color: var(--darggrey);
    font-family: var(--text-medium-normal-font-family);
    font-size: var(--text-medium-normal-font-size);
    font-style: var(--text-medium-normal-font-style);
    font-weight: var(--text-medium-normal-font-weight);
    letter-spacing: var(--text-medium-normal-letter-spacing);
    line-height: var(--text-medium-normal-line-height);
    margin-top: -5px;
    position: relative;
}

.listing .requirements-c-uni {
    align-self: stretch;
    color: #000000;
    font-family: var(--text-medium-normal-font-family);
    font-size: var(--text-medium-normal-font-size);
    font-style: var(--text-medium-normal-font-style);
    font-weight: var(--text-medium-normal-font-weight);
    height: 181px;
    letter-spacing: var(--text-medium-normal-letter-spacing);
    line-height: var(--text-medium-normal-line-height);
    margin-top: -5px;
    position: relative;
}

.listing .link-2 {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 80px;
}

.listing .icon-share-ios {
    height: 70px !important;
    position: relative !important;
    width: 70px !important;
}

.listing .text-wrapper-3 {
    color: var(--darggrey);
    font-family: var(--text-small-normal-font-family);
    font-size: var(--text-small-normal-font-size);
    font-style: var(--text-small-normal-font-style);
    font-weight: var(--text-small-normal-font-weight);
    letter-spacing: var(--text-small-normal-letter-spacing);
    line-height: var(--text-small-normal-line-height);
    margin-left: -0.50px;
    margin-right: -0.50px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
}