.status-onboarding-bar {
    margin: 60px 0px;
    height: 16px;
}

.web-onboarding .overlap-group {
    height: 15px;
    position: relative;
}

.web-onboarding .line {
    height: 1px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 7px;
}

.web-onboarding .progress {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 11px 11px;
    height: 15px;
    justify-content: space-between;
    left: 0;
    position: absolute;
    top: 0;
}

.web-onboarding .ellipse {
    background-color: var(--primary);
    border-radius: 7.5px;
    height: 15px;
    position: relative;
    width: 15px;
}

.web-onboarding .ellipse-2 {
    background-color: #d9d9d9;
    border-radius: 7.5px;
    height: 15px;
    position: relative;
    width: 15px;
}

@media screen and (min-width:1440px) {
    .web-onboarding .progress {
        width: 800px;
    }

    .web-onboarding .line {
        width: 800px;
    }

    .web-onboarding .status-onboarding-bar {
        width: 800px;
    }
}

@media screen and (max-width:1440px) {
    .web-onboarding .progress {
        width: 500px;
    }

    .web-onboarding .line {
        width: 500px;
    }

    .web-onboarding .status-onboarding-bar {
        width: 500px;
    }
}